export function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
}

export function validateRegister(state) {
    const errors = {}
    if (state.username.length === 0) errors['username'] = 'Username is required'
    if (state.password !== state.password2 && state.password.length > 0) {
        errors['password2'] = "Passwords don't match"
    }
    if (state.password.length === 0) errors['password'] = 'Password is required'
    if (state.password2.length === 0)
        errors['password2'] = 'Password is required'

    if (!validateEmail(state.email)) errors['email'] = 'Is not valid!'
    return errors
}

export function validateLogin(state) {
    const errors = {}
    if (!validateEmail(state.email)) errors['email'] = 'Is not valid!'
    if (state.email.length === 0) errors['email'] = 'Please enter your email'
    if (state.password.length === 0)
        errors['password'] = 'Please enter your password'
    return errors
}
