import React from 'react'
import { connect } from 'react-redux'

import { makeStyles, Button, Container } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'

import { SET_EMAIL, SET_USER, SET_PASSWORD, SET_PASSWORD2 } from './constants'

const useStyles = makeStyles(theme => ({
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}))

const Register = ({ state, dispatchInput, handleRegister }) => {
    const classes = useStyles()

    const errors = state.errors || {}

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <div className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        helperText={errors.username}
                        error={Boolean(errors.username)}
                        onChange={e => dispatchInput(SET_USER, e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        helperText={errors.email}
                        error={Boolean(errors.email)}
                        onChange={e => dispatchInput(SET_EMAIL, e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="password"
                        label="password"
                        name="password"
                        type="password"
                        autoComplete="password"
                        helperText={errors.password}
                        error={Boolean(errors.password)}
                        fullWidth
                        onChange={e =>
                            dispatchInput(SET_PASSWORD, e.target.value)
                        }
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="password2"
                        label="password2"
                        name="password2"
                        type="password"
                        autoComplete="password2"
                        helperText={errors.password2}
                        error={Boolean(errors.password2)}
                        fullWidth
                        onChange={e =>
                            dispatchInput(SET_PASSWORD2, e.target.value)
                        }
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleRegister}
                    >
                        Register
                    </Button>
                </div>
            </div>
        </Container>
    )
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
})

export default connect(mapStateToProps, {})(Register)
