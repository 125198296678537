import React, { useState, useEffect, useReducer } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import _ from 'lodash'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import {
    Typography,
    makeStyles,
    CssBaseline,
    Link,
    Container,
    Box,
} from '@material-ui/core'
import TechAseanLogo from 'images/tech_asean_with_words_colored.png'

import Login from './Login'
import Register from './Register'
import { login } from '../../redux/auth'
import { validateLogin, validateRegister } from './util'
import {
    SET_EMAIL,
    SET_USER,
    SET_PASSWORD,
    SET_REMEMBER,
    SET_PASSWORD2,
    SET_ERRORS,
    SWITCH_TAB,
    INITIAL_STATE,
} from './constants'

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: '1px solid #e7e7e7',
        borderRadius: '15px',
    },
    logo: {
        margin: theme.spacing(1),
        height: '100px',
    },
}))

function inputReducer(state, { type, payload }) {
    switch (type) {
        case SET_EMAIL:
            return { ...state, email: payload }
        case SET_USER:
            return { ...state, username: payload }
        case SET_PASSWORD:
            return { ...state, password: payload }
        case SET_REMEMBER:
            return { ...state, remember: !state.remember }
        case SET_PASSWORD2:
            return { ...state, password2: payload }
        case SET_ERRORS:
            return { ...state, errors: payload }
        case SWITCH_TAB:
            return INITIAL_STATE
        default:
            return state
    }
}

const Auth = ({ auth = { user: {} }, history, ...reduxActions }) => {
    const classes = useStyles()
    const [tab, setTab] = useState(0)
    const [state, dispatch] = useReducer(inputReducer, INITIAL_STATE)
    const dispatchError = error =>
        dispatch({ type: SET_ERRORS, payload: error })
    const dispatchInput = (type, payload) =>
        dispatch({ type: type, payload: payload })

    useEffect(() => {
        if (auth.user.isAuthenticated) history.push('/network')
    }, [auth.user.isAuthenticated])

    const handleRegister = () => {
        const errors = validateRegister(state)
        if (_.isEmpty(errors)) {
            const { username, email, password } = state
            axios
                .post('/auth/register', { username, email, password })
                .then(res => {
                    // TODO add reduxActions.notify("Email successfully created!"), create notification system
                    setTab(0)
                    return null
                })
                .catch(err => {
                    if (err.response && err.response.data) {
                        return dispatchError(err.response.data.errors)
                    }
                })
        }
        return dispatchError(errors)
    }

    const handleLogin = () => {
        const errors = validateLogin(state)
        if (_.isEmpty(errors)) {
            const { email, password, remember } = state
            axios
                .post('/auth/login', { email, password, remember })
                .then(res => {
                    const { createdAt, updatedAt, ...userPayload } = res.data
                    reduxActions.login(userPayload)
                    return history.push('/network')
                })
                .catch(err => {
                    if (err.response && err.response.data) {
                        return dispatchError(err.response.data.errors)
                    }
                })
        } else {
            return dispatchError(errors)
        }
    }

    const handleChange = (event, value) => {
        dispatch({ type: SWITCH_TAB })
        setTab(value)
    }

    const Show =
        tab === 0 ? (
            <Login
                state={state}
                dispatchInput={dispatchInput}
                handleLogin={handleLogin}
            />
        ) : (
            <Register
                state={state}
                dispatchInput={dispatchInput}
                handleRegister={handleRegister}
            />
        )

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <img src={TechAseanLogo} className={classes.logo}></img>
                <Tabs
                    value={tab}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                >
                    <Tab label="Login" />
                    <Tab label="Register" />
                </Tabs>
                <div>{Show}</div>

                <Box mt={8}>
                    <Copyright />
                </Box>
            </div>
        </Container>
    )
}

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                TechASEAN
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    )
}

const mapStateToProps = state => {
    return { auth: state.auth }
}

const mapDispatchToProps = { login }

export default connect(mapStateToProps, mapDispatchToProps)(Auth)
