import './index.scss'
import React, { Component } from 'react'
import axios from 'axios'
import history from 'components/History/History'
import Card from 'components/Card/Card'

class Services extends Component {
    state = {
        data: '',
    }

    componentWillMount() {
        // To be uncommented
        // axios({
        //     url: '/protectedRoute',
        //     method: 'GET'
        // })
        //     .then((response) => {
        //         this.setState({ data: response.data })
        //     })
        //     .catch(() => {
        //         history.push(`/login`);
        //     })
    }

    render() {
        const { data } = this.state

        return (
            <>
                <h1>Services</h1>
                <div className="services-section">
                    {data}
                    <Card
                        details={{
                            header: 'Hiring',
                            description: 'Find good talent at a good price',
                            imageUrl:
                                'https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80',
                        }}
                        onClick={() =>
                            this.props.history.push('/services/hiring')
                        }
                    ></Card>
                    <Card
                        details={{
                            header: 'Accelerators',
                            description: 'Bring your startup to the next level',
                            imageUrl:
                                'https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80',
                        }}
                        onClick={() =>
                            this.props.history.push('/services/accelerators')
                        }
                    ></Card>
                    <Card
                        details={{
                            header: 'Raise Funding',
                            description:
                                'Find out who you sohuld talk to for your next round of funding',
                            imageUrl:
                                'https://images.unsplash.com/photo-1534951009808-766178b47a4f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80',
                        }}
                        onClick={() => 0}
                    ></Card>
                </div>
            </>
        )
    }
}

export default Services
