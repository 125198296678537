import './index.scss'
import React from 'react'
import PropTypes from 'prop-types'
import TextWithInlineIcons from 'components/Typography/TextWithInlineIcons'
import { makeStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import constants from 'constants.js'
import DarkButton from 'components/Button/DarkButton'
import LightButton from 'components/Button/LightButton'
import Grid from '@material-ui/core/Grid'
import {
    Language as LanguageIcon,
    Facebook as FacebookIcon,
    LinkedIn as LinkedInIcon,
    Twitter as TwitterIcon,
    MailOutlineIcon,
} from '@material-ui/icons'

const useStyles = makeStyles({
    section: {
        marginTop: 12,
    },
    pos: {
        marginBottom: 12,
    },
    linkText: {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
})

const ProfileCard = props => {
    const classes = useStyles()
    const { profile, renderLocalNetworkGraph } = props
    const {
        roles: { STARTUP_ROLE, FOUNDER_ROLE, ACCELERATOR_ROLE },
    } = constants

    let connectedEntities = {
        [STARTUP_ROLE.id]: [],
        [FOUNDER_ROLE.id]: [],
        [ACCELERATOR_ROLE.id]: [],
    }

    profile.connections.forEach(connection => {
        if (connection.neighbourRoleId === STARTUP_ROLE.id) {
            connectedEntities[STARTUP_ROLE.id].push(connection)
        }
        if (connection.neighbourRoleId === FOUNDER_ROLE.id) {
            connectedEntities[FOUNDER_ROLE.id].push(connection)
        }
        if (connection.neighbourRoleId === ACCELERATOR_ROLE.id) {
            connectedEntities[ACCELERATOR_ROLE.id].push(connection)
        }
    })

    const renderNeighbourEntitiesSection = role => {
        console.log(connectedEntities[role.id])
        return (
            <div className="profile-card__section-item">
                <h6 className="profile-card__section-item-header">
                    {/* {`${role.name} (${connectedEntities[role.id].length})`} */}
                    {`${role.name}`}
                </h6>
                {connectedEntities[role.id].map(connection => {
                    return (
                        <TextWithInlineIcons
                            key={connection.neighbourId}
                            text={connection.neighbourName}
                            onClick={() => {
                                renderLocalNetworkGraph(connection.neighbourId)
                            }}
                        >
                            {connection.neighbourWebsite && (
                                <LanguageIcon
                                    className="profile-card__section-item-icon"
                                    onClick={() =>
                                        window.open(
                                            connection.neighbourWebsite,
                                            '_blank'
                                        )
                                    }
                                />
                            )}
                            {connection.neighbourFacebook && (
                                <FacebookIcon
                                    className="profile-card__section-item-icon"
                                    onClick={() =>
                                        window.open(
                                            connection.neighbourFacebook,
                                            '_blank'
                                        )
                                    }
                                />
                            )}
                            {connection.neighbourTwitter && (
                                <LinkedInIcon
                                    className="profile-card__section-item-icon"
                                    onClick={() =>
                                        window.open(
                                            connection.neighbourTwitter,
                                            '_blank'
                                        )
                                    }
                                />
                            )}
                            {connection.neighbourLinkedIn && (
                                <TwitterIcon
                                    className="profile-card__section-item-icon"
                                    onClick={() =>
                                        window.open(
                                            connection.neighbourLinkedIn,
                                            '_blank'
                                        )
                                    }
                                />
                            )}
                            {/* {connection.neighbourEmail && (
                                <a href={`mailto:{}`}>
                                    {connection.neighbourEmail}
                                </a>
                            )} */}
                        </TextWithInlineIcons>
                    )
                })}
            </div>
        )
    }

    return (
        <div className="profile-card">
            <h4 className="profile-card__header">{profile.name}</h4>
            <div className="profile-card__section">
                <p>{profile.description}</p>

                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <DarkButton
                            width="100%"
                            onClick={() =>
                                props.history.push(`/entities/${profile.id}`)
                            }
                        >
                            Profile
                        </DarkButton>
                    </Grid>
                    {profile.website && (
                        <Grid item xs={6}>
                            <LightButton
                                width="100%"
                                onClick={() =>
                                    window.open(profile.website, '_blank')
                                }
                            >
                                Website
                            </LightButton>
                        </Grid>
                    )}
                </Grid>

                {profile.role_id.includes(STARTUP_ROLE.id) &&
                    renderNeighbourEntitiesSection(FOUNDER_ROLE)}
                {profile.role_id.includes(STARTUP_ROLE.id) &&
                    renderNeighbourEntitiesSection(ACCELERATOR_ROLE)}
                {profile.role_id.includes(ACCELERATOR_ROLE.id) &&
                    renderNeighbourEntitiesSection(STARTUP_ROLE)}
                {profile.role_id.includes(FOUNDER_ROLE.id) &&
                    renderNeighbourEntitiesSection(STARTUP_ROLE)}
            </div>
        </div>
    )
}

ProfileCard.propTypes = {
    profile: PropTypes.shape({}),
}

export default withRouter(ProfileCard)
