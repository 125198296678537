import './index.scss'
import React, { memo } from 'react'
import HorizontalCard from 'components/Card/HorizontalCard'

const HiringPage = props => {
    return (
        <>
            <h1>Hiring</h1>
            <HorizontalCard
                details={{
                    header: 'Wonderlabs',
                    description: 'Find good talent at a good price',
                    pricing: '$10/hr',
                    targetNodeId: 7536,
                }}
            ></HorizontalCard>
        </>
    )
}

HiringPage.propTypes = {}

export default memo(HiringPage)
