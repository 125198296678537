export const INITIAL_STATE = {
  email: "",
  username: "",
  password: "",
  password2: "",
  errors: {},
  remember: false
}

// action types
export const SET_EMAIL = 'SET_EMAIL',
  SET_USER = "SET_USER",
  SET_PASSWORD = "SET_PASSWORD",
  SET_REMEMBER = "SET_REMEMBER",
  SET_PASSWORD2 = "SET_PASSWORD2",
  SET_ERRORS = "SET_ERRORS",
  SWITCH_TAB = "SWITCH_TAB"