import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

const PurpleCheckbox = withStyles({
    root: {
        color: '#63618c',
        '&$checked': {
            color: '#63618c',
        },
    },
    checked: {},
})(props => <Checkbox color="default" {...props} />)

class CustomCheckbox extends PureComponent {
    renderCheckboxes = () => {
        const checkboxOptions = this.props.options
        return checkboxOptions.map(option => {
            return (
                <FormControlLabel
                    key={option.role.id}
                    control={
                        <PurpleCheckbox
                            checked={option.checked}
                            onChange={() => {
                                this.props.onChange(option.role.id)
                            }}
                            value="checkedB"
                        />
                    }
                    label={option.role.name}
                />
            )
        })
    }

    render() {
        return <FormGroup row>{this.renderCheckboxes()}</FormGroup>
    }
}

export default CustomCheckbox
