import comprehensiveNodes from 'sample_data/comprehensiveNodes.json'
import shortestPaths from 'sample_data/shortestPaths.json'

const buildShortestPathString = targetNodeId => {
    let shortestPathArray = shortestPaths.data[targetNodeId]
    if (shortestPathArray.length <= 1) return `No shortest path found`

    let shortestPathString = 'Shortest Path: '
    shortestPathArray.forEach((entity, index) => {
        const entityName = comprehensiveNodes.data[entity].name
        shortestPathString += index === 0 ? entityName : ` --> ${entityName}`
    })
    return shortestPathString
}

const constructNodeArrayFromNodeObj = nodeData => {
    return Object.keys(nodeData).map(key => nodeData[key])
}

const constructLocalLinksArray = (
    links,
    localNodes,
    entityPairsOnShortestPath,
    showUserNode
) => {
    var localLinks = []
    const localNodeKeys = Object.keys(localNodes)
    links.forEach(link => {
        if (
            localNodeKeys.includes(link.sourceId) &&
            localNodeKeys.includes(link.targetId)
        ) {
            if (
                showUserNode &&
                (entityPairsOnShortestPath.has(
                    `${link.targetId}, ${link.sourceId}`
                ) ||
                    entityPairsOnShortestPath.has(
                        `${link.sourceId}, ${link.targetId}`
                    ))
            ) {
                link.color = 'black'
            } else {
                link.color = '#e5e5e5'
            }
            localLinks.push(link)
        }
    })
    return localLinks
}

const buildShortestPath = (targetNodeId, shortestPaths) => {
    const buildEntityPairsOnShortestPath = (shortestPathArray = []) => {
        const entityPairsOnShortestPath = new Set()
        for (let i = 0; i < shortestPathArray.length - 1; i++) {
            entityPairsOnShortestPath.add(
                `${shortestPathArray[i]}, ${shortestPathArray[i + 1]}`
            )
        }
        return entityPairsOnShortestPath
    }

    const entityPairsOnShortestPath = buildEntityPairsOnShortestPath(
        shortestPaths.data[targetNodeId]
    )
    return entityPairsOnShortestPath
}

const constructLocalNodesObj = (
    node,
    degreeWidth,
    comprehensiveNodes,
    showUserNode,
    shortestPaths
) => {
    const includeShortestPathNodes = (
        targetNodeId,
        nodes,
        comprehensiveNodes,
        shortestPaths
    ) => {
        if (shortestPaths.data[targetNodeId]) {
            for (const id of shortestPaths.data[targetNodeId]) {
                nodes[id] = comprehensiveNodes.data[id]
            }
        }
    }

    var localNodes = {}
    localNodes[node.id] = comprehensiveNodes.data[node.id]

    for (let step = 0; step < degreeWidth; step++) {
        for (var key in localNodes) {
            comprehensiveNodes.data[key].connections.forEach(connection => {
                if (!localNodes[connection.neighbourId]) {
                    localNodes[connection.neighbourId] =
                        comprehensiveNodes.data[connection.neighbourId]
                }
            })
        }
    }

    if (showUserNode)
        includeShortestPathNodes(
            node.id,
            localNodes,
            comprehensiveNodes,
            shortestPaths
        )
    return localNodes
}

const graphUtils = {
    buildShortestPathString,
    constructNodeArrayFromNodeObj,
    constructLocalLinksArray,
    buildShortestPath,
    constructLocalNodesObj,
}

export default graphUtils
