import './index.scss'
import React, { Component } from 'react'
import axios from 'axios'
import EntityCard from 'components/Card/EntityCard'
import CircularLoader from 'components/Loader/CircularLoader'
import SearchBar from 'components/SearchBar/SearchBar'
import constants from 'constants.js'
import techAseanRequest from 'utilities/techAseanRequest'
import Grid from '@material-ui/core/Grid'
import ColorRoundedLightButton from 'components/Button/ColorRoundedLightButton'
import PaginatedTable from 'components/Table/PaginatedTable'

const data = [
    {
        id: 3,
        parent_role_id: 31,
        author_id: 5,
        image_id: 10427,
        name: '(These)Abilities',
        website: 'http://www.theseabilities.com/',
        contact_email: '',
        contact_no: '',
        private_contact_no: 0,
        description:
            '(these)abilities is a design & technology company that aims to "Disable Disabilities" by designing & building products that level the playing field for Persons with Disabilities (PwDs) at work, at home and during play.\r\n\r\nOur products are designed together with PwDs using high and low tech, design thinking as well as community engagement and should be used by everyone, not just PwDs.',
        version: 11,
        active: 1,
        created_at: '2019-07-01T12:00:00.000Z',
        updated_at: '2019-09-02T01:52:13.000Z',
        deleted_at: null,
        alias: '',
        keywords: '',
        tags: '',
        internal_id: 'SG-S.00001',
        country_origin: 'Singapore',
        official_name: '(These)Abilities Pte. Ltd.',
        facebook_url: '',
        twitter_url: '',
        linked_in_url: '',
    },
    {
        id: 4,
        parent_role_id: 31,
        author_id: 5,
        image_id: 1312,
        name: '@Bsolute Aircon',
        website: 'http://www.absoluteaircon.com.sg',
        contact_email: 'sales@absoluteaircon.com.sg',
        contact_no: '',
        private_contact_no: 0,
        description:
            '@bsolute Aircon Pte Ltd is the leading aircon company in Singapore.',
        version: 13,
        active: 1,
        created_at: '2019-07-01T12:00:00.000Z',
        updated_at: '2019-09-05T16:08:00.000Z',
        deleted_at: null,
        alias: '',
        keywords: '',
        tags: '',
        internal_id: 'SG-S.00002',
        country_origin: 'Singapore',
        official_name: '@Bsolute Aircon Pte. Ltd.',
        facebook_url: '',
        twitter_url: '',
        linked_in_url: '',
    },
    {
        id: 5,
        parent_role_id: 31,
        author_id: 5,
        image_id: 1313,
        name: '@Bsolute Cleaning',
        website: 'http://www.absolutecleaning.com.sg',
        contact_email: 'sales@absolutecleaning.com.sg',
        contact_no: '+65 9186 9762',
        private_contact_no: 0,
        description:
            '@bsolute Cleaning is the leading house cleaning & part time maid company in Singapore.',
        version: 12,
        active: 1,
        created_at: '2019-07-01T12:00:00.000Z',
        updated_at: '2019-09-05T16:07:23.000Z',
        deleted_at: null,
        alias: '',
        keywords: '',
        tags: '',
        internal_id: 'SG-S.00003',
        country_origin: 'Singapore',
        official_name: '@Bsolute Cleaning Pte. Ltd.',
        facebook_url: '',
        twitter_url: '',
        linked_in_url: '',
    },
    {
        id: 6,
        parent_role_id: 31,
        author_id: 5,
        image_id: 1314,
        name: '#Vinyloftheday',
        website: 'https://www.vinyloftheday.com/',
        contact_email: '',
        contact_no: '',
        private_contact_no: 0,
        description:
            'CommGate is a Singapore-based award-winning company that provides digital solutions, omni-channel e-commerce system, and CloudERP to empower SMEs in South-East Asia to grow, profit, and succeed.',
        version: 11,
        active: 1,
        created_at: '2019-07-01T12:00:00.000Z',
        updated_at: '2019-09-05T16:07:18.000Z',
        deleted_at: null,
        alias: '',
        keywords: '',
        tags: '',
        internal_id: 'SG-S.00004',
        country_origin: 'Singapore',
        official_name: 'Votd Llp',
        facebook_url: '',
        twitter_url: '',
        linked_in_url: '',
    },
    {
        id: 7,
        parent_role_id: 31,
        author_id: 5,
        image_id: 1318,
        name: '01 Innovations',
        website: 'http://www.01innovations.com.sg/',
        contact_email: '',
        contact_no: '+65 6309 2727',
        private_contact_no: 0,
        description:
            '01 Innovations provides information technology solutions for SMEs to enhance their business operations.',
        version: 11,
        active: 1,
        created_at: '2019-07-01T12:00:00.000Z',
        updated_at: '2020-01-07T01:50:00.000Z',
        deleted_at: null,
        alias: '',
        keywords: '',
        tags: '',
        internal_id: 'SG-S.00005',
        country_origin: 'Singapore',
        official_name: '01 Innovations Pte. Ltd.',
        facebook_url: '',
        twitter_url: '',
        linked_in_url: '',
    },
    {
        id: 8,
        parent_role_id: 31,
        author_id: 5,
        image_id: 10434,
        name: '1 Commercial Space',
        website: 'http://www.1commercialspace.com/',
        contact_email: '',
        contact_no: '',
        private_contact_no: 0,
        description:
            "One of the main reasons to go with a serviced Office Rental Singapore arrangement is because you can enjoy low cost offices. You can make a payment on a monthly basis for the office space, making it easy for you to budget out your finances. The great thing is that these arrangements usually include utilities, reception facilities, business rates, rent, and more. This means you don't have to worry about other costs during the month, which can really save you a huge amount of money.",
        version: 13,
        active: 1,
        created_at: '2019-07-01T12:00:00.000Z',
        updated_at: '2019-09-05T16:07:06.000Z',
        deleted_at: null,
        alias: '',
        keywords: '',
        tags: '',
        internal_id: 'SG-S.00006',
        country_origin: 'Singapore',
        official_name: '',
        facebook_url: '',
        twitter_url: '',
        linked_in_url: '',
    },
    {
        id: 9,
        parent_role_id: 31,
        author_id: 5,
        image_id: null,
        name: '1 Market By Chef Wan',
        website: 'http://www.1market.com.sg/',
        contact_email: 'enquiry@1market.com.sg',
        contact_no: '(656)341-9159',
        private_contact_no: 0,
        description:
            '1Market BIG buffet in Singapore by Chef Wan Indulge in a gastronomic spread of appetizers, salads.',
        version: 4,
        active: 1,
        created_at: '2019-07-01T12:00:00.000Z',
        updated_at: '2019-07-17T04:49:52.000Z',
        deleted_at: '2019-07-17T04:49:52.000Z',
        alias: '',
        keywords: '',
        tags: '',
        internal_id: 'SG-S.00007',
        country_origin: 'Singapore',
        official_name: '',
        facebook_url: '',
        twitter_url: '',
        linked_in_url: '',
    },
    {
        id: 10,
        parent_role_id: 31,
        author_id: 5,
        image_id: 1325,
        name: '100Offer',
        website: 'https://www.100offer.com/',
        contact_email: '',
        contact_no: '',
        private_contact_no: 0,
        description:
            '100offer is an opportunity marketplace for talented people to discover the job of their dreams at leading tech companies.',
        version: 14,
        active: 1,
        created_at: '2019-07-01T12:00:00.000Z',
        updated_at: '2020-01-06T15:30:10.000Z',
        deleted_at: null,
        alias: '',
        keywords: '',
        tags: '',
        internal_id: 'SG-S.00008',
        country_origin: 'Singapore',
        official_name: '100Offer Pte. Ltd.',
        facebook_url: '',
        twitter_url: '',
        linked_in_url: '',
    },
    {
        id: 11,
        parent_role_id: 31,
        author_id: 5,
        image_id: 10448,
        name: '101 Credit',
        website: 'Defunct',
        contact_email: '',
        contact_no: '',
        private_contact_no: 0,
        description:
            '101 Credit Loan, a Licensed Moneylender based in Singapore, provides Personal Loan, Foreigner Loan, Fast Cash and Business Loan.',
        version: 10,
        active: 1,
        created_at: '2019-07-01T12:00:00.000Z',
        updated_at: '2019-09-05T16:07:01.000Z',
        deleted_at: null,
        alias: '',
        keywords: '',
        tags: '',
        internal_id: 'SG-S.00009',
        country_origin: 'Singapore',
        official_name: '101 Credit Pte. Ltd.',
        facebook_url: '',
        twitter_url: '',
        linked_in_url: '',
    },
    {
        id: 12,
        parent_role_id: 31,
        author_id: 5,
        image_id: 10459,
        name: '1010Genome',
        website: 'https://www.1010genome.com/',
        contact_email: '',
        contact_no: '',
        private_contact_no: 0,
        description:
            'At 1010Genome, we provide the customized data analysis for Cancer Genome sequencing & cancer mutation discovery. Our data analysis service caters the many other applications like genome sequencing & assembly. Contact us today!',
        version: 11,
        active: 1,
        created_at: '2019-07-01T12:00:00.000Z',
        updated_at: '2019-09-05T16:06:56.000Z',
        deleted_at: null,
        alias: '',
        keywords: '',
        tags: '',
        internal_id: 'SG-S.00010',
        country_origin: 'Singapore',
        official_name: 'Genome Tech Pte. Ltd.',
        facebook_url: '',
        twitter_url: '',
        linked_in_url: '',
    },
]

const Tab = props => {
    const { onClick, isSelected } = props
    return (
        <div
            onClick={onClick}
            className={
                'entities-tab-bar-item ' + (isSelected ? 'selected-tab' : '')
            }
        >
            {props.children}
        </div>
    )
}

const TabBar = props => {
    const { handleChange, selectedRoleId } = props
    return (
        <div className="entities-tab-bar">
            <Tab
                isSelected={selectedRoleId === constants.roles.STARTUP_ROLE.id}
                onClick={() => {
                    handleChange(constants.roles.STARTUP_ROLE)
                }}
            >
                STARTUPS
            </Tab>
            <Tab
                isSelected={selectedRoleId === constants.roles.INVESTOR_ROLE.id}
                onClick={() => {
                    handleChange(constants.roles.INVESTOR_ROLE)
                }}
            >
                INVESTORS
            </Tab>
            <Tab
                isSelected={
                    selectedRoleId === constants.roles.ACCELERATOR_ROLE.id
                }
                onClick={() => {
                    handleChange(constants.roles.ACCELERATOR_ROLE)
                }}
            >
                ACCELERATORS
            </Tab>
        </div>
    )
}

class EntityList extends Component {
    state = {
        page: 0,
        search: '',
        roleId: {
            name: '',
            id: null,
        },
        numEntities: 0,
    }

    componentDidMount() {
        const roleId =
            this.props.location.state && this.props.location.state.roleId
                ? this.props.location.state.roleId
                : constants.roles.STARTUP_ROLE

        this.loadData(roleId)
        // this.setState({
        //     entities: data,
        // })
    }

    loadData = roleId => {
        const { search } = this.state
        techAseanRequest
            .get(`/entities`, { search, roleId: roleId.id, page: 0 })
            .then(({ data }) => {
                this.setState({
                    entities: data.rows,
                    numRows: data.count,
                    roleId,
                })
            })
            .catch(err => {
                console.log(err)
            })

        techAseanRequest
            .get(`/entities/count`, { search, roleId: roleId.id, page: 0 })
            .then(({ data }) => {
                console.log(data)
                const numEntities = data.count
                this.setState({
                    numEntities,
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    handleSearch = e => {
        const { roleId } = this.state
        e.preventDefault()
        this.loadData(roleId)
    }

    handleTextChange = (key, e) => {
        this.setState({ [key]: e.target.value })
    }

    handlePageChange = (e, nextPage) => {
        const { search, roleId } = this.state
        this.setState({ page: nextPage })
        techAseanRequest
            .get(`/entities`, { search, roleId: roleId.id, page: nextPage })
            .then(({ data }) => {
                this.setState({
                    entities: data.rows,
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    render() {
        const { entities, roleId, numEntities, numRows, page } = this.state
        console.log(roleId)
        return (
            <div className="entities-tab">
                <h1 className="entities-header">All Entities</h1>
                <TabBar
                    handleChange={this.loadData}
                    selectedRoleId={roleId.id}
                ></TabBar>
                <Grid container spacing={1} className="entities-tab-body">
                    <Grid item xs={12} sm={4}>
                        <div className="entities-stats">
                            <p className="entities-stats-text">
                                {`Total Number of ${roleId.name}s:`}
                            </p>
                            <p className="entities-stats-count">
                                {numEntities}
                            </p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            className="entities-list"
                        >
                            <Grid item xs={12} sm={9}>
                                <form onSubmit={this.handleSearch}>
                                    <SearchBar
                                        onChange={this.handleTextChange}
                                        stateToChange="search"
                                        text={this.state.search}
                                        placeholder={`Enter name of ${roleId.name}`}
                                    ></SearchBar>
                                </form>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <ColorRoundedLightButton
                                    width={'100%'}
                                    onClick={this.handleSearch}
                                >
                                    SEARCH
                                </ColorRoundedLightButton>
                            </Grid>
                            <div className="entities-list__table">
                                {!entities ? (
                                    <CircularLoader></CircularLoader>
                                ) : (
                                    <PaginatedTable
                                        headers={['Name', 'Country']}
                                        rows={entities}
                                        page={page}
                                        totalNumRows={numRows}
                                        handlePageChange={this.handlePageChange}
                                        onRowClick={entityId =>
                                            this.props.history.push(
                                                `/entities/${entityId}`
                                            )
                                        }
                                    ></PaginatedTable>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

EntityList.propTypes = {}

export default EntityList
