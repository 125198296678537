import './index.scss'
import React, { memo } from 'react'
import graphUtils from 'utilities/graphUtils.js'
import ShortestPath from 'components/Graph/ShortestPath'

const HorizontalCard = props => {
    const {
        details: { header, description, pricing, targetNodeId },
    } = props
    return (
        <div className="custom-horizontal-card" onClick={props.onClick}>
            <div className="custom-horizontal-card-text-section">
                <h4 className="custom-horizontal-card-header">{header}</h4>
                <p className="custom-card-description">{description}</p>
                <p>{pricing}</p>
                <ShortestPath targetNodeId={targetNodeId}></ShortestPath>
            </div>
        </div>
    )
}

HorizontalCard.propTypes = {}

export default memo(HorizontalCard)
