import './index.scss'
import React, { memo } from 'react'

const Card = props => {
    const {
        details: { header, description, imageUrl },
    } = props
    return (
        <div className="custom-card" onClick={props.onClick}>
            <div
                style={{
                    height: '200px',
                    width: '100%',
                    background: `url("${imageUrl}")`,
                    backgroundSize: 'fill',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}
                onClick={props.onClick}
            ></div>
            <div className="custom-card-text-section">
                <h4 className="custom-card-header">{header}</h4>
                <p className="custom-card-description">{description}</p>
            </div>
        </div>
    )
}

Card.propTypes = {}

export default memo(Card)
