import axios from 'axios'
import store from '../store'
import { LOGOUT_USER } from '../redux/auth'

const { REACT_APP_BACKEND_BASE_URL } = process.env

if (!REACT_APP_BACKEND_BASE_URL) {
    throw new Error(
        'REACT_APP_BACKEND_BASE_URL environment variable not provided!'
    )
}

const PUBLIC_PATHS = ['/login', '/']

axios.defaults.baseURL = REACT_APP_BACKEND_BASE_URL
axios.defaults.timeout = 10000
axios.defaults.withCredentials = true

// axios.interceptors.response.use(
//     function(response) {
//         return response
//     },
//     function(error) {
//         const originalRequest = error.config
//         if (
//             error.response &&
//             error.response.status === 401 &&
//             originalRequest.url !== `/auth/refresh`
//         ) {
//             return refreshSession()
//                 .then(res => {
//                     return axios(originalRequest)
//                 })
//                 .catch(error => {
//                     if (
//                         error.response &&
//                         error.response.status === 401 &&
//                         !PUBLIC_PATHS.includes(window.location.pathname)
//                     ) {
//                         // should only be triggered if refreshSession fails and client is accessing protected routes
//                         alert('Authentication error, please login again')
//                         store.dispatch({ type: LOGOUT_USER })
//                     }
//                     return Promise.reject(error)
//                 })
//         } else {
//             throw error
//         }
//     }
// )

// function refreshSession() {
//     console.log('refresh')
//     return axios.post('/auth/refresh')
// }
