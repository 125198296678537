import './App.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import React, { Component } from 'react'
import ColorNavBar from 'components/NavBar/ColorNavBar'
import Auth from 'containers/Auth'
import NetworkTab from 'containers/NetworkTab/NetworkTab'
import HomeTab from 'containers/HomeTab/HomeTab'
import AboutUsTab from 'containers/AboutUsTab/AboutUsTab'
import PrivacyPolicyTab from 'containers/PrivacyPolicyTab/PrivacyPolicyTab'
import EntityTab from 'containers/EntityTab/List/EntityList'
import EntityDetail from 'containers/EntityTab/Detail/EntityDetail'
import ServicesTab from 'containers/ServicesTab/ServicesTab'
import HiringPage from 'containers/ServicesTab/HiringPage/HiringPage'
import AcceleratorsPage from 'containers/ServicesTab/AcceleratorsPage/AcceleratorsPage'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import BoxLoader from 'components/Loader/BoxLoader'
import PrivateRoute from './components/PrivateRoute'
import Footer from 'components/Footer/Footer'
import ScrollToTop from 'components/ScrollToTop/ScrollToTop'
import store from './store'
import { LOGIN_USER } from './redux/auth'
import { Provider } from 'react-redux'
import axios from 'axios'
import TechAseanLogo from './images/tech_asean_with_words_white.png'

const THEME = createMuiTheme({
    typography: {
        fontFamily:
            '"Montserrat", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    },
})

class App extends Component {
    state = {
        isSideDrawerOpen: false,
        isLoading: false,
    }

    componentDidMount() {
        // axios
        //     .get('/user')
        //     .then(res => {
        //         store.dispatch({ type: LOGIN_USER, user: res.data })
        //         this.setState({ isLoading: false })
        //     })
        //     .catch(err => {
        //         this.setState({ isLoading: false })
        //     })
    }

    handleSideDrawer = isSideDrawerOpen => {
        this.setState({ isSideDrawerOpen })
    }

    renderComponentWithNavWithoutSideDrawer = (
        props,
        Component,
        disableFooter
    ) => {
        return (
            <>
                <ColorNavBar hideSideDrawer={true} {...props} />
                <div className="external-container--without-side-drawer">
                    <Component {...props} />
                    {!disableFooter && <Footer></Footer>}
                </div>
            </>
        )
    }

    renderComponentWithNav = (props, Component, disableFooter) => {
        const { isSideDrawerOpen } = this.state
        return (
            <>
                <ColorNavBar
                    handleSideDrawer={this.handleSideDrawer}
                    {...props}
                />
                <div className="external-container--with-side-drawer">
                    <div
                        className={
                            'external-container--with-side-drawer-child ' +
                            (isSideDrawerOpen
                                ? 'external-container--side-drawer-open'
                                : ' ')
                        }
                    >
                        <div className={'component-container'}>
                            <Component {...props} />
                        </div>
                        {!disableFooter && <Footer></Footer>}
                    </div>
                </div>
            </>
        )
    }

    render() {
        if (this.state.isLoading)
            return (
                <div className="home-loading-page">
                    <img
                        src={TechAseanLogo}
                        className="home-loading-page__loading-logo"
                    />
                    <BoxLoader></BoxLoader>
                </div>
            )
        const {
            auth: { isAuthenticated },
        } = store.getState()
        return (
            <div className="App">
                <MuiThemeProvider theme={THEME}>
                    <Provider store={store}>
                        <BrowserRouter>
                            <ScrollToTop>
                                <Switch>
                                    <Route
                                        exact
                                        path="/login"
                                        component={Auth}
                                    />
                                    {/* <PrivateRoute exact path="/network" render={(props) => this.renderComponentWithNav(props, NetworkTab)} />
                                <PrivateRoute exact path="/portfolio" render={(props) => this.renderComponentWithNav(props, ServicesTab)} />*/}
                                    <Route
                                        exact
                                        path="/"
                                        render={props =>
                                            this.renderComponentWithNavWithoutSideDrawer(
                                                props,
                                                HomeTab,
                                                true
                                            )
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/entities"
                                        render={props =>
                                            this.renderComponentWithNav(
                                                props,
                                                EntityTab
                                            )
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/entities/:entityId"
                                        render={props =>
                                            this.renderComponentWithNav(
                                                props,
                                                EntityDetail
                                            )
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/network"
                                        render={props =>
                                            this.renderComponentWithNav(
                                                props,
                                                NetworkTab
                                            )
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/about_us"
                                        render={props =>
                                            this.renderComponentWithNav(
                                                props,
                                                AboutUsTab
                                            )
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/privacy_policy"
                                        render={props =>
                                            this.renderComponentWithNav(
                                                props,
                                                PrivacyPolicyTab
                                            )
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/services"
                                        render={props =>
                                            this.renderComponentWithNav(
                                                props,
                                                ServicesTab
                                            )
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/services/hiring"
                                        render={props =>
                                            this.renderComponentWithNav(
                                                props,
                                                HiringPage
                                            )
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/services/accelerators"
                                        render={props =>
                                            this.renderComponentWithNav(
                                                props,
                                                AcceleratorsPage
                                            )
                                        }
                                    />
                                    <Route
                                        path="/"
                                        render={() =>
                                            isAuthenticated ? (
                                                <Redirect to="/network" />
                                            ) : (
                                                <Redirect to="/network" />
                                                // TODO: Change back to redirect
                                                // <Redirect to="/login" />
                                            )
                                        }
                                    />
                                </Switch>
                            </ScrollToTop>
                        </BrowserRouter>
                    </Provider>
                </MuiThemeProvider>
            </div>
        )
    }
}

export default App
