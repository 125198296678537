import './index.scss'
import React, { memo } from 'react'

const BoxLoader = props => {
    return (
        <div className="box-loader">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    )
}

BoxLoader.propTypes = {}

export default memo(BoxLoader)
