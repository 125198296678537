import './index.scss'
// import sampleNodeData from 'sample_data/node_data_restructured.json';
// import sampleNodeLinks from 'sample_data/node_links.json';
import fullLinksArray from 'sample_data/fullLinksArray.json'
import entityNamesArray from 'sample_data/entityNamesArray.json'
import comprehensiveNodes from 'sample_data/comprehensiveNodes.json'
import nodeArrayWithoutFounders from 'sample_data/nodeArrayWithoutFounders.json'
import linksArrayWithoutFounders from 'sample_data/linksArrayWithoutFounders.json'
import graphUtils from 'utilities/graphUtils.js'
import shortestPaths from 'sample_data/shortestPaths.json'
import ProfileCard from 'components/ProfileCard/ProfileCard'
import LightButton from 'components/Button/LightButton'
import ShortestPath from 'components/Graph/ShortestPath'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import ColorRoundedLightButton from 'components/Button/ColorRoundedLightButton'

import NetworkGraphSearchBar from 'components/SearchBar/NetworkGraphSearchBar'
import Checkbox from 'components/Checkbox/Checkbox'
import constants from 'constants.js'
import NetworkGraph from 'components/NetworkGraph/NetworkGraph'

const DEFAULT_DEGREE_WIDTH = 3
const checkboxOptions = [
    { role: constants.roles.STARTUP_ROLE, checked: true },
    { role: constants.roles.FOUNDER_ROLE, checked: true },
    { role: constants.roles.ACCELERATOR_ROLE, checked: true },
]

class NetworkTab extends Component {
    state = {
        searchTerm: '',
        degreeWidth: DEFAULT_DEGREE_WIDTH,
        selectedNode: null,
        isLocalNetwork: false,
        rolesToFilter: new Set(),
        showUserNode: true,
        loading: true,
        networkGraphTickCount: 40,
        networkGraphNodeArray: [],
        networkGraphLinksArray: [],
        networkGraphSelectedNodeId: -1,
        networkGraphIsAnimate: false,
        networkGraphIsChanged: false,
        // chartSize: null // for ResizeObserver to dynamically set the size of the chart
    }

    componentDidMount() {
        // this.renderFullNetworkGraph
        this.renderSearchNetworkGraph()
        // d3v4.select(".chart").attr("display", "none")

        // Resize Observer can be used to resize the chart svg dimensions dynamically according to bounding conrtainer

        // const ro = new ResizeObserver(entries => {
        //     for (let entry of entries) {
        //         // console.log('Element:', entry.target);
        //         setTimeout(() => {
        //             const cr = entry.contentRect;
        //             const { height, width } = cr;
        //             console.log(`Element size: ${cr.width}px x ${cr.height}px`);
        //             this.setState({ chartSize: { height, width } })
        //         }, 2000)
        //     }
        // });
        // ro.observe(chartContainer);
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.selectedNode !== this.state.selectedNode ||
            prevState.rolesToFilter !== this.state.rolesToFilter ||
            prevState.searchTerm !== this.state.searchTerm ||
            prevState.degreeWidth !== this.state.degreeWidth
        ) {
            // alternative implementation is to wrap each chart handler with a HOF that does this for us
            this.setState({ loading: true, networkGraphTickCount: 40 })
        }
    }

    // =============== Data Processing Methods ===============
    constructFilteredNodes = nodes => {
        var filteredNodes = []
        nodes.forEach(node => {
            // improve logic
            const filteredRoleIds = node.role_id.filter(
                roleId => !this.state.rolesToFilter.has(roleId)
            )
            if (filteredRoleIds.length) filteredNodes.push(node)
        })
        return filteredNodes
    }

    constructFilteredLinks = links => {
        return links.filter(
            link =>
                !this.state.rolesToFilter.has(link.sourceRoleId) &&
                !this.state.rolesToFilter.has(link.targetRoleId)
        )
    }

    // =============== Handler Methods ===============
    handleCheckboxChange = roleId => {
        const { rolesToFilter } = this.state
        if (rolesToFilter.has(roleId)) {
            let newRolesToFilter = new Set(rolesToFilter)
            newRolesToFilter.delete(roleId)
            this.setState(
                () => ({
                    rolesToFilter: newRolesToFilter,
                }),
                this.renderFilteredNetworkGraph
            )
        } else {
            this.setState(
                ({ rolesToFilter }) => ({
                    rolesToFilter: new Set(rolesToFilter).add(roleId),
                }),
                this.renderFilteredNetworkGraph
            )
        }
    }

    handleSearchTermChange = searchTerm => {
        this.setState({
            searchTerm,
        })
    }

    handleDegreeWidthChange = e => {
        if (e.target.value > 0) {
            this.setState(
                {
                    degreeWidth: e.target.value || 5,
                },
                this.reRenderNetworkGraph
            )
        }
    }

    renderFilteredNetworkGraph = () => {
        this.reRenderNetworkGraph(true)
    }

    // =============== Render Methods ===============
    reRenderNetworkGraph = withFilter => {
        const { selectedNode } = this.state
        if (selectedNode) {
            this.renderLocalNetworkGraph(selectedNode, withFilter)
        } else {
            this.renderFullNetworkGraph()
        }
    }

    renderSearchNetworkGraph = () => {
        const searchTerm = this.state.searchTerm || 'Carousell'
        for (var key in comprehensiveNodes.data) {
            if (
                comprehensiveNodes.data[key].name.toLowerCase() ===
                searchTerm.toLowerCase()
            ) {
                return this.renderLocalNetworkGraph(
                    comprehensiveNodes.data[key]
                )
            }
        }
    }

    renderLocalNetworkGraph = (node, withFilter) => {
        const { degreeWidth, showUserNode } = this.state
        const entityPairsOnShortestPath = graphUtils.buildShortestPath(
            node.id,
            shortestPaths
        )
        const localNodes = graphUtils.constructLocalNodesObj(
            node,
            degreeWidth,
            comprehensiveNodes,
            showUserNode,
            shortestPaths
        )
        let nodeArray = graphUtils.constructNodeArrayFromNodeObj(localNodes)
        let linksArray = graphUtils.constructLocalLinksArray(
            fullLinksArray.data,
            localNodes,
            entityPairsOnShortestPath,
            showUserNode
        )
        if (withFilter) {
            nodeArray = this.constructFilteredNodes(nodeArray)
            linksArray = this.constructFilteredLinks(linksArray)
        }
        this.setState({
            selectedNode: node,
            isLocalNetwork: true,
        })
        this.renderNetworkGraph(nodeArray, linksArray, node.id, true)
    }

    renderLocalNetworkGraphFromCard = nodeId => {
        this.renderLocalNetworkGraph(comprehensiveNodes.data[nodeId])
    }

    renderFullNetworkGraph = () => {
        this.setState({
            selectedNode: null,
            isLocalNetwork: false,
            networkGraphTickCount: 200,
        })
        this.renderNetworkGraph(
            nodeArrayWithoutFounders.data,
            linksArrayWithoutFounders.data,
            null,
            false
        )
    }

    renderNetworkGraph = (nodeArray, linksArray, selectedNodeId, isAnimate) => {
        const { networkGraphIsChanged } = this.state
        this.setState({
            networkGraphNodeArray: nodeArray,
            networkGraphLinksArray: linksArray,
            networkGraphSelectedNodeId: selectedNodeId,
            networkGraphIsAnimate: isAnimate,
            networkGraphIsChanged: !networkGraphIsChanged,
        })
    }

    render() {
        const {
            isLocalNetwork,
            selectedNode,
            networkGraphNodeArray,
            networkGraphLinksArray,
            networkGraphSelectedNodeId,
            networkGraphIsAnimate,
            networkGraphIsChanged,
            networkGraphTickCount,
        } = this.state

        return (
            <div className="network-tab">
                <h1 className="network-tab__header">
                    Explore the
                    <span className="network-tab__header--emphasis">
                        Network
                    </span>
                </h1>
                <Grid
                    container
                    spacing={3}
                    className="network-tab__main-section"
                >
                    <Grid item xs={12} sm={8}>
                        {/* <LightButton onClick={this.renderFullNetworkGraph}>
                            Show Full Network
                        </LightButton> */}

                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={12} sm={9}>
                                <NetworkGraphSearchBar
                                    handleSearchTermChange={
                                        this.handleSearchTermChange
                                    }
                                    searchTerm={this.state.searchTerm}
                                    renderSearchNetworkGraph={
                                        this.renderSearchNetworkGraph
                                    }
                                    suggestions={entityNamesArray.data}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <ColorRoundedLightButton
                                    width={'100%'}
                                    onClick={this.renderSearchNetworkGraph}
                                >
                                    SEARCH
                                </ColorRoundedLightButton>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" spacing={3}>
                            <Grid item xs={4}>
                                {isLocalNetwork && (
                                    <TextField
                                        label="Degree Width"
                                        onChange={this.handleDegreeWidthChange}
                                        defaultValue={DEFAULT_DEGREE_WIDTH}
                                        type="number"
                                        margin="normal"
                                    />
                                )}
                            </Grid>
                            <Grid item xs={8}>
                                <Checkbox
                                    options={checkboxOptions.map(option => {
                                        return {
                                            ...option,
                                            checked: !this.state.rolesToFilter.has(
                                                option.role.id
                                            ),
                                        }
                                    })}
                                    onChange={this.handleCheckboxChange}
                                />
                            </Grid>
                        </Grid>
                        <ShortestPath
                            targetNodeId={selectedNode ? selectedNode.id : null}
                            renderLocalNetworkGraph={
                                this.renderLocalNetworkGraphFromCard
                            }
                        ></ShortestPath>
                        <NetworkGraph
                            nodeArray={networkGraphNodeArray}
                            linksArray={networkGraphLinksArray}
                            selectedNodeId={networkGraphSelectedNodeId}
                            isAnimate={networkGraphIsAnimate}
                            isChanged={networkGraphIsChanged}
                            tickCount={networkGraphTickCount}
                            renderLocalNetworkGraph={
                                this.renderLocalNetworkGraph
                            }
                        ></NetworkGraph>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={4}
                        className="profile-card-container"
                    >
                        {selectedNode && (
                            <ProfileCard
                                profile={selectedNode}
                                renderLocalNetworkGraph={
                                    this.renderLocalNetworkGraphFromCard
                                }
                            />
                        )}
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default NetworkTab

NetworkTab.propTypes = {}
