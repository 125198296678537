import './index.scss'
// import sampleNodeData from 'sample_data/node_data_restructured.json';
// import sampleNodeLinks from 'sample_data/node_links.json';
import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import CircularLoader from 'components/Loader/CircularLoader'
import * as d3v4 from 'd3v4'
import constants from 'constants.js'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

class NetworkGraph extends Component {
    state = {
        isLoading: true,
    }

    componentWillMount = () => {
        this.renderNetworkGraph()
    }

    componentDidUpdate = prevProps => {
        if (prevProps.isChanged === this.props.isChanged) return
        this.renderNetworkGraph()
    }

    zoomIn = () => {}

    renderNetworkGraph = () => {
        d3v4.select('.chart').attr('display', 'none')

        const {
            nodeArray,
            linksArray,
            isAnimate,
            selectedNodeId,
            renderLocalNetworkGraph,
            tickCount,
        } = this.props

        // For circular progress stuff
        let tickCounter = tickCount
        this.setState({ isLoading: true })

        const nodes = nodeArray
        const links = linksArray
        const graph = { nodes, links }
        // const graph = {
        //     nodes: [
        //         { id: "Myriel", group: 1 },
        //         { id: "Napoleon", group: 1 },
        //         { id: "Child1", group: 10 },
        //         { id: "Child2", group: 10 },
        //         { id: "Brujon", group: 4 },
        //         { id: "Mme.Hucheloup", group: 8 }
        //     ],
        //     links: [
        //         { source: "Napoleon", target: "Myriel", value: 1 },
        //         { source: "Mlle.Baptistine", target: "Myriel", value: 8 },
        //         { source: "Judge", target: "Bamatabois", value: 2 },
        //         { source: "Mme.Hucheloup", target: "Courfeyrac", value: 1 },
        //         { source: "Mme.Hucheloup", target: "Gavroche", value: 1 },
        //         { source: "Mme.Hucheloup", target: "Enjolras", value: 1 }
        //     ]
        // }

        // if both d3v3 and d3v4 are loaded, we'll assume
        // that d3v4 is called d3v4, otherwise we'll assume
        // that d3v4 is the default (d3)
        // if (typeof d3v4 == 'undefined')
        //     d3v4 = d3;

        let parentWidth = 700
        let parentHeight = 500

        var svg = d3v4
            .select('.chart')
            .attr('width', 'inherit')
            .attr('height', parentHeight)

        // remove any previous graphs
        svg.selectAll('.g-main').remove()

        var gMain = svg.append('g').classed('g-main', true)

        // Provides draggable interface
        var rect = gMain
            .append('rect')
            .attr('width', parentWidth)
            .attr('height', parentHeight)
            .style('fill', 'white')

        var gDraw = gMain.append('g').classed('g-draw', true)

        var zoom = d3v4.zoom().on('zoom', zoomed)

        gMain.call(zoom)

        function zoomed() {
            console.log('hello')
            console.log(d3v4.event.transform)
            gDraw.attr('transform', d3v4.event.transform)
        }

        var color = d3v4.scaleOrdinal(d3v4.schemeCategory20)

        if (!('links' in graph)) {
            console.log('Graph is missing links')
            return
        }

        // var nodes = {};
        // var i;
        // for (i = 0; i < graph.nodes.length; i++) {
        // nodes[graph.nodes[i].name] = graph.nodes[i];
        // graph.nodes[i].weight = 1.01; // seems useless
        // }

        var link = gDraw
            .append('g')
            .attr('class', 'link')
            .selectAll('line')
            .data(graph.links)
            .enter()
            .append('line')
            .attr('stroke', d => d.color)
            .attr('stroke-width', function(d) {
                return d.width
            })

        link.sort((a, b) => {
            // links now on top, but below node
            if (a.color === b.color && a.color === 'black') return 0
            if (a.color === 'black') return 1
            if (b.color === 'black') return -1
            return 0
        })

        const tooltip = d3v4
            .select('.network-graph')
            .append('div')
            .attr('class', 'tooltip')
            .html('Tooltip')

        // circles cannot contain text, we need a group for circle and text
        var node = gDraw
            .append('g')
            .classed('node-group', true) // css class for debugging
            .selectAll('g')
            .data(graph.nodes)
            .enter()
            .append('g')
            .classed('node', true) // css class for debugging

        node.append('circle')
            .attr('r', d => d.size)
            .attr('fill', function(d) {
                if (selectedNodeId !== null && d.id === selectedNodeId) {
                    return '#2b2927'
                }
                if ('color' in d) {
                    return d.color
                } else return color(d.group)
            })
            .classed('network-graph__node', true)
            // .attr("stroke", function (d) {
            //     if (selectedNodeId !== null && d.id === selectedNodeId) {
            //         return "green"
            //     }
            // })
            // .attr("stroke-width", function (d) {
            //     if (selectedNodeId !== null && d.id === selectedNodeId) {
            //         return 2
            //     }
            // })
            .on('mouseover', d => {
                tooltip
                    .html(`<h5>${d.name}</h5> <p>${d.description}</p>`)
                    .style('left', d3v4.event.pageX + 3 + 'px')
                    .style('top', d3v4.event.pageY + 3 + 'px')
                    .style('opacity', 0.9)
                    .style('display', 'block')
            })
            .on('mouseout', () => {
                tooltip
                    .style('opacity', 0)
                    .style('left', '0px')
                    .style('top', '0px')
                    .style('display', 'none')
            })
            .on('click', d => {
                renderLocalNetworkGraph(d)
                tooltip
                    .style('opacity', 0)
                    .style('left', '0px')
                    .style('top', '0px')
                    .style('display', 'none')
            })

        node.call(
            d3v4
                .drag()
                .on('start', dragstarted)
                .on('drag', dragged)
                .on('end', dragended)
        )

        const SIZE_TO_SHOW_NAME = 10

        node.filter(d => d.size >= SIZE_TO_SHOW_NAME)
            .append('text')
            .attr('dx', 0)
            .classed('network-graph__node-text', true)
            .text(d => d.name)

        // So that text can be seen
        node.sort((a, b) => {
            return a.size > b.size ? 1 : -1
        })

        // add titles for mouseover blurbs
        // node.append("title")
        //     .text(function (d) {
        //         if ('name' in d)
        //             return d.name;
        //         else
        //             return d.id;
        //     });

        //! Attempt at text force simulation
        // const textNodes = graph.nodes.filter(n => n.size >= SIZE_TO_SHOW_NAME);
        // const textSimulation = d3v4.forceSimulation(textNodes).on("tick", ticked);
        // // var link_force =  d3v4.forceLink(data.links)
        // //     .id(function(d) { return d.id; }).distance(minDistance).strength(1);

        // var charge_force = d3v4.forceManyBody()
        //     .strength(-1000);

        // var width = gDraw.select("g").attr("width")
        // var height = gDraw.select("g").attr("height")

        // var center_force = d3v4.forceCenter(width / 2, height / 2);

        // textSimulation
        //     .force("charge_force", charge_force)
        //     // .force("center_force", center_force)
        //     // .force('y', d3v4.forceY(height / 2).strength(0.10));
        //     // .force("links",link_force)
        // graph.nodes.push(textNodes);
        const boundTick = ticked.bind(this)

        function ticked() {
            // update node and line positions at every step of
            // the force simulation
            link.attr('x1', function(d) {
                return d.source.x
            })
                .attr('y1', function(d) {
                    return d.source.y
                })
                .attr('x2', function(d) {
                    return d.target.x
                })
                .attr('y2', function(d) {
                    return d.target.y
                })

            node.attr('transform', function(d) {
                // ensures that text is also follows the force
                return 'translate(' + d.x + ',' + d.y + ')'
            })

            if (tickCounter === 0) {
                console.log('Show chart')
                d3v4.select('.chart').attr('display', 'block')
                this.setState({ isLoading: false })
            }

            tickCounter = tickCounter - 1
            // Below kept because alittle uncertain of the implications of using transform

            // node.attr("cx", function (d) { return d.x; })
            //     .attr("cy", function (d) { return d.y; });
        }

        var simulation = d3v4
            .forceSimulation(graph.nodes)
            .force(
                'link',
                d3v4
                    .forceLink(graph.links)
                    .id(d => {
                        return d.id
                    })
                    .distance(d => {
                        return 45
                        //var dist = 20 / d.value;
                        //console.log('dist:', dist);

                        // return dist;
                    })
            )
            .force('charge', d3v4.forceManyBody())
            .force(
                'center',
                d3v4.forceCenter(parentWidth / 2, parentHeight / 2)
            )
            .force('x', d3v4.forceX(parentWidth / 2))
            .force('y', d3v4.forceY(parentHeight / 2))
            .on('tick', boundTick)
        // .on("end", () => {
        //     d3v4.select(".chart").attr("display", "block");
        //     this.setState({ isLoading: false });
        // })

        // if (!isAnimate) {
        //     simulation.stop()
        //     for (var i = 0; i < 300; ++i) {
        //         simulation.tick(); // for the simulation to run awhile until it reaches a stop (nodes no longer moving from the force)
        //         boundTick();
        //     }
        // }

        function dragstarted(d) {
            if (!d3v4.event.active) simulation.alphaTarget(0.9).restart()

            if (!d.selected) {
                // if this node isn't selected, then we have to unselect every other node
                node.classed('selected', function(p) {
                    return (p.selected = p.previouslySelected = false)
                })
            }

            d3v4.select(this).classed('selected', function(p) {
                d.previouslySelected = d.selected
                return (d.selected = true)
            })

            node.filter(function(d) {
                return d.selected
            }).each(function(d) {
                //d.fixed |= 2;
                d.fx = d.x
                d.fy = d.y
            })
        }

        function dragged(d) {
            //d.fx = d3v4.event.x;
            //d.fy = d3v4.event.y;
            node.filter(function(d) {
                return d.selected
            }).each(function(d) {
                d.fx += d3v4.event.dx
                d.fy += d3v4.event.dy
            })
        }

        function dragended(d) {
            if (!d3v4.event.active) simulation.alphaTarget(0)
            d.fx = null
            d.fy = null
            node.filter(function(d) {
                return d.selected
            }).each(function(d) {
                //d.fixed &= ~6;
                d.fx = null
                d.fy = null
            })
        }

        // var texts = ['Use the scroll wheel to zoom',
        //     'Hold the shift key to select nodes']

        // svg.selectAll('text')
        //     .data(texts)
        //     .enter()
        //     .append('text')
        //     .attr('x', 900)
        //     .attr('y', function (d, i) { return 470 + i * 18; })
        //     .text(function (d) { return d; });
        // return graph
    }

    render() {
        const { isLoading } = this.state
        return (
            <div className="network-graph">
                {isLoading ? (
                    <div className="spinner-container">
                        <CircularLoader size={80} />
                    </div>
                ) : (
                    <div className="network-graph__panel">
                        <HelpOutlineIcon className="network-graph__panel-icon"></HelpOutlineIcon>
                    </div>
                )}

                <div className="network-graph__chart-container">
                    <svg className="chart"></svg>
                </div>
            </div>
        )
    }
}

export default NetworkGraph

NetworkGraph.propTypes = {}
