import './index.scss'
import React from 'react'
import PropTypes from 'prop-types'

export default function TextWithInlineIcons(props) {
    const { text, children, onClick } = props
    return (
        <>
            <p className="text-with-inline-icon">
                <span onClick={onClick} className={onClick && 'pointer'}>
                    {text}
                </span>
                <span className="pointer">{children}</span>
            </p>
        </>
    )
}

TextWithInlineIcons.propTypes = {
    profile: PropTypes.shape({}),
}
