import './index.scss'
import React, { memo } from 'react'
import { withRouter } from 'react-router-dom'
import TechAseanLogoWhite from 'images/tech_asean_with_words_white.png'
import CopyrightIcon from '@material-ui/icons/Copyright'
import MailOutlineIcon from '@material-ui/icons/MailOutline'

const Footer = props => {
    return (
        <div className="footer" onClick={props.onClick}>
            <div className="footer__top-section">
                <img src={TechAseanLogoWhite} className="footer__logo"></img>
                <div className="footer__text">
                    <div className="footer__links-section">
                        <p className="footer__text-header">SITES</p>
                        <a
                            className="footer__link"
                            onClick={() => {
                                props.history.push('/about_us')
                            }}
                        >
                            About
                        </a>
                        <a
                            className="footer__link"
                            onClick={() => {
                                props.history.push('/entities')
                            }}
                        >
                            Directory
                        </a>
                        <a
                            className="footer__link"
                            onClick={() => {
                                props.history.push('/network')
                            }}
                        >
                            Network
                        </a>
                    </div>
                    <div className="footer__about">
                        <p className="footer__text-header">ABOUT</p>
                        <p className="footer__text-description">
                            TechASEAN aims to provide not only the most
                            comprehensive and up-to-date directories of tech
                            startups in ASEAN, but also information on their
                            founders, investors, incubators, technology sources
                            and facilitating intermediaries, and how these are
                            in turn dynamically connected with one another.
                        </p>
                    </div>
                </div>
            </div>
            <div className="footer__bottom-section">
                <div>
                    <p>
                        <MailOutlineIcon className="footer__icon"></MailOutlineIcon>
                        hello@techasean.io
                    </p>
                    <p>
                        <CopyrightIcon className="footer__icon"></CopyrightIcon>
                        Copyright 2020 TechASEAN {' / '}
                        <span
                            onClick={() => {
                                props.history.push('/privacy_policy')
                            }}
                            className="footer__bottom-section-privacy-policy"
                        >
                            Privacy Policy
                        </span>
                    </p>
                </div>
                <div>
                    <p>POWERED BY:</p>
                    <p>With.io</p>
                </div>
            </div>
        </div>
    )
}

Footer.propTypes = {}

export default withRouter(Footer)
