const constants = {
    roles: {
        STARTUP_ROLE: {
            name: 'Startup',
            id: '59',
        },
        PERSON_ROLE: {
            name: 'Person',
            id: '29',
        },
        ORGANIZATION_ROLE: {
            name: 'Organization',
            id: '31',
        },
        INVESTOR_ROLE: {
            name: 'Investor',
            id: '71',
        },
        FOUNDER_ROLE: {
            name: 'Founder',
            id: '72',
        },
        ACCELERATOR_ROLE: {
            name: 'Accelerator',
            id: '70',
        },
        UNIVERSITY_ROLE: {
            name: 'University',
            id: '73',
        },
        STOCK_EXCHANGE_ROLE: {
            name: 'Stock Exchange',
            id: '84',
        },
    },
}

module.exports = constants
