import './index.scss'
import React, { memo } from 'react'

const CircularLoader = props => {
    return <div className="circular-loader"></div>
}

CircularLoader.propTypes = {}

export default memo(CircularLoader)
