import './index.scss'
import React, { memo } from 'react'

const AboutUs = props => {
    return (
        <div className="about-us-tab container-padding">
            <h3>About Us</h3>
            <br></br>
            <h4>What is TechASEAN?</h4>
            <p>
                TechASEAN is an online platform that maps the technology startup
                ecosystems of ASEAN economies.
            </p>

            <p>
                TTechASEAN aims to provide not only the most comprehensive and
                up-to-date directories of tech startups in ASEAN, but also
                information on their founders, investors, incubators, technology
                sources and facilitating intermediaries, and how these are in
                turn dynamically connected with one another. It is the pattern
                of connectivity among these key players, as captured by
                TechASEAN, that provides powerful insights to be generated on
                the key movers and shakers of the entrepreneurship ecosystem.
            </p>

            <h4>How does TechASEAN work?</h4>
            <p>
                TechASEAN is built on an open-wiki platform that will allow all
                players in the entrepreneurship ecosystem to own and update
                their profiles, including in particular their connections to
                other players. This real-time information “crowdsourcing”
                platform is supplemented by a natural language processing (NLP)
                engine that is being developed to crawl the internet to
                semi-automate information updating by a curation team. The
                platform also provides Application Program Interfaces (APIs) to
                automate and integrate information feeds from various partner
                organizations in the entrepreneurship ecosystem, such as profile
                updates, event announcements and press releases.
            </p>
            <p>
                TechASEAN is powered the proprietary APEIRO technology,
                co-developed by NUS Enterprise and NUS portfolio company
                Wonderlabs Pte Ltd. APEIRO is an infinitely flexible database
                engine that allows a data scientist to store, structure and
                capture data collected in a format that lends itself to be
                easily presented/analyzed as a social graph.
            </p>
            <p>
                TechASEAN also serves as a natural platform for engaging the
                entrepreneurship community via online surveys and sentiment
                polls, and for disseminating the survey findings back to the
                community.
            </p>

            <h4>What is in TechASEAN?</h4>
            <p>
                At the soft launch in July 2019, TechASEAN will comprise
                comprehensive data on the ecosystems of two economies -
                Singapore and Indonesia, and basic information on startups of
                eight other ASEAN economies.
            </p>
            <ul>
                <li>
                    Singapore: 7,753 startups, 408 VCs, 584 facilitators, more
                    than 30,000 startup linkages
                </li>
                <li>Indonesia: 5,628 startups, 32 VCs, 70 facilitators</li>
                <li>Vietnam: 2,172 startups</li>
                <li>Malaysia: 2,139 startups</li>
                <li>Philippines: 1,455 startups</li>
                <li>Thailand: 1,150 startups</li>
                <li>Myanmar: 207 startups</li>
                <li>Cambodia: 106 startups</li>
                <li>Brunei: 19 startups</li>
                <li>Laos: 8 startups</li>
            </ul>
            <p>
                The Insights section of the platform will present analytics
                about the Indonesian and Singaporean ecosystems, including
                comparative analysis of their trends and structures, as well as
                highlighting the interconnectivity between the two.
            </p>

            <h4>The Connectors 2019</h4>
            <p>
                The Connectors 2019 Singapore is the inaugural ranking of the
                most influential ecosystem players in Singapore. Unlike the
                subjective “power rankings” that proliferate online, The
                Connectors is an objective ranking which quantifies the linkage
                data collated in the TechASEAN database. Applying the concept of
                node centrality from network theory, an influence score is
                calculated for every organization and person in the ecosystem.
            </p>
            <p>
                The TechASEAN team plans to officially release The Connectors
                2019 Singapore ranking in late 2019.
            </p>
            <p>
                The methodology will be applied to the Indonesian data to
                construct The Connectors Indonesia, to be launched at a later
                date.
            </p>

            <h4>TechASEAN Vision</h4>
            <p>
                Leveraging on the capabilities of the APEIRO technology,
                TechASEAN has the potential to be a dynamic platform for online
                community engagement, to generate and sustain cross-border
                conversations about topical and timely issues of interest.
            </p>
            <p>
                Upon completion, the platform will be the most comprehensive,
                curated library depository for research and data on ASEAN’s
                entrepreneurship ecosystems and how they benchmark against each
                other within the region.
            </p>

            <h4>The team behind TechASEAN</h4>
            <p>The TechASEAN platform and data are owned by NUS Enterprise.</p>
            <p>
                TechASEAN is being developed and curated by a research team at
                NUS Enterprise, working in collaboration with a data analytics
                start-up, Wonderlabs. The collaboration is led by Professor Wong
                Poh Kam, Senior Director of NUS Entrepreneurship Centre and
                Professor at the NUS Business School, who is a leading
                entrepreneurship academic scholar and educator in Asia.
            </p>
            <p>
                The team is committed to keeping the TechASEAN platform and data
                open for the entrepreneurship community, in line with
                Singapore’s Smart Nation vision of enabling innovation and
                entrepreneurship through open data.
            </p>

            <h6>TechASEAN Core Team</h6>

            <ul>
                <li>Principal Investigator: Prof Wong Poh Kam, NUS</li>
                <li>Data Master: Ivan Chang, Wonderlabs</li>
                <li>Analysis & Content Manager: Ho Yuen Ping, NUS</li>
                <li>Project Coordinator: Keith Tan, Wonderlabs</li>
                <li>Technical Guru: Hanif Ali, Wonderlabs</li>
            </ul>
        </div>
    )
}

AboutUs.propTypes = {}

export default memo(AboutUs)
