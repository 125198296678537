import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { SET_EMAIL, SET_PASSWORD, SET_REMEMBER } from "./constants"

const useStyles = makeStyles(theme => ({
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const Login = ({ state, dispatchInput, handleLogin }) => {
    const classes = useStyles();

    const { errors } = state;

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <div className={classes.form} noValidate >
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        helperText={errors.email}
                        error={Boolean(errors.email)}
                        onChange={e => dispatchInput(SET_EMAIL, e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        helperText={errors.password}
                        error={Boolean(errors.password)}
                        onChange={e => dispatchInput(SET_PASSWORD, e.target.value)}
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" onChange={e => dispatchInput(SET_REMEMBER, null)} />}
                        label="Remember me"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleLogin}
                    >
                        Sign In
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Container>
    );
}

export default Login;