const REGISTER_USER = "REGISTER_USER"
export const LOGIN_USER = "LOGIN_USER"
export const LOGOUT_USER = "LOGOUT_USER"


const INITIAL_STATE = { user: {} }

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REGISTER_USER:
      return state
    case LOGIN_USER:
      return { ...state, user: { ...action.user, isAuthenticated: true } }
    case LOGOUT_USER:
      return INITIAL_STATE
    default:
      return state
  }
}

export const login = (user) => {
  return { type: LOGIN_USER, user: { ...user, isAuthenticated: true } }
}

export const logout = () => {
  return { type: LOGOUT_USER }
}

export default authReducer