import './index.scss'
import React, { memo } from 'react'

const EntityCard = props => {
    const {
        details: { header, description, imageUrl },
    } = props
    return (
        <div className="entity-card" onClick={props.onClick}>
            <div className="entity-card-text-section">
                <h6 className="entity-card-header">{header}</h6>
            </div>
        </div>
    )
}

EntityCard.propTypes = {}

export default memo(EntityCard)
