import './index.scss'
import React, { memo } from 'react'

const DarkButton = props => {
    const { width } = props
    return (
        <button
            className="custom-btn dark-btn"
            style={{ width }}
            onClick={props.onClick}
        >
            {props.children}
        </button>
    )
}

DarkButton.propTypes = {}

export default memo(DarkButton)
