import './index.scss'
import React, { memo } from 'react'
import HorizontalCard from 'components/Card/HorizontalCard'

const AcceleratorsPage = props => {
    return (
        <>
            <h1>Accelerators</h1>
            <HorizontalCard
                details={{
                    header: 'NUS Furnace',
                    description: 'Be empowered by NUS resources',
                    pricing: '$300/month for office space',
                    targetNodeId: 23450,
                }}
            ></HorizontalCard>
            <HorizontalCard
                details={{
                    header: 'Entrepreneur First',
                    description:
                        'Entrepreneur First is the world’s leading talent investor. We invest time and money in the world’s most talented and ambitious individuals, helping them to find a co-founder, develop an idea, and start a company. So far, we’ve helped 2,000+ people create 300+ companies, worth a combined $2bn',
                    pricing: '',
                    targetNodeId: 23391,
                }}
            ></HorizontalCard>
            <HorizontalCard
                details={{
                    header: '500 Startups',
                    description:
                        "500 Startups isn't just a name — we really have invested in over 500 companies. The numbers speak for themselves. 1095+. companies. 45+. countries",
                    pricing: '',
                    targetNodeId: 22890,
                }}
            ></HorizontalCard>
            <HorizontalCard
                details={{
                    header: 'NTUitive',
                    description: 'The innovation hub of NTU',
                    pricing: '',
                    targetNodeId: 23448,
                }}
            ></HorizontalCard>
            <HorizontalCard
                details={{
                    header: 'A*StartCentral',
                    description:
                        'Your home away from lab. We inspire, innovate with, and empower researcher-entrepreneurs to build successful ventures enabled by deep technology.',
                    pricing: '',
                    targetNodeId: 23347,
                }}
            ></HorizontalCard>
        </>
    )
}

AcceleratorsPage.propTypes = {}

export default memo(AcceleratorsPage)
