import './index.scss'
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import TechAseanLogoWhite from 'images/tech_asean_with_words_white.png'
import HomeIcon from '@material-ui/icons/Home'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import RoomServiceIcon from '@material-ui/icons/RoomService'
import ListIcon from '@material-ui/icons/List'
import BubbleChartIcon from '@material-ui/icons/BubbleChart'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import SearchIcon from '@material-ui/icons/Search'

const NavBarButton = props => {
    return (
        <button className="nav-bar-btn" onClick={props.onClick}>
            {props.children}
        </button>
    )
}

const ToggleSideDrawerButton = props => {
    return (
        <button className="toggle-side-drawer-btn" onClick={props.onClick}>
            {props.children}
        </button>
    )
}

const SideDrawerButton = props => {
    let icon = props.children[0]
    let text = props.children[1]
    return (
        <button
            className={
                'side-drawer-btn ' +
                (props.isSelected ? 'side-drawer-btn-selected' : '')
            }
            onClick={props.onClick}
        >
            {icon}
            <div
                className={
                    'side-drawer-btn-text ' +
                    (props.isSideDrawerOpen ? '' : 'hide-text')
                }
            >
                {text}
            </div>
        </button>
    )
}

const SideDrawer = props => {
    const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(false)
    const [selectedButtonId, setSelectedButtonId] = useState(-1)
    return (
        <>
            <div
                className={
                    'side-drawer ' +
                    (isSideDrawerOpen ? 'side-drawer-open' : '')
                }
            >
                <ToggleSideDrawerButton
                    onClick={() => {
                        props.handleSideDrawer(!isSideDrawerOpen)
                        setIsSideDrawerOpen(!isSideDrawerOpen)
                    }}
                    isSideDrawerOpen={isSideDrawerOpen}
                >
                    {isSideDrawerOpen ? (
                        <ChevronLeftIcon fontSize="inherit"></ChevronLeftIcon>
                    ) : (
                        <ChevronRightIcon fontSize="inherit"></ChevronRightIcon>
                    )}
                </ToggleSideDrawerButton>
                <div className="side-drawer-btns">
                    {/* <SideDrawerButton
                        isSideDrawerOpen={isSideDrawerOpen}
                        onClick={() => {
                            props.history.push('/')
                            setSelectedButtonId(1)
                        }}
                        isSelected={selectedButtonId === 1}
                    >
                        <HomeIcon fontSize="inherit"></HomeIcon>Home
                    </SideDrawerButton>
                    <SideDrawerButton isSideDrawerOpen={isSideDrawerOpen}>
                        <PermIdentityIcon fontSize="inherit"></PermIdentityIcon>
                        Profile
                    </SideDrawerButton>
                    <SideDrawerButton
                        isSideDrawerOpen={isSideDrawerOpen}
                        onClick={() => {
                            props.history.push('/services')
                            setSelectedButtonId(2)
                        }}
                        isSelected={selectedButtonId === 2}
                    >
                        <RoomServiceIcon fontSize="inherit"></RoomServiceIcon>
                        Services
                    </SideDrawerButton> */}
                    <SideDrawerButton
                        isSideDrawerOpen={isSideDrawerOpen}
                        onClick={() => {
                            props.history.push('/entities')
                            setSelectedButtonId(3)
                        }}
                        isSelected={selectedButtonId === 3}
                    >
                        <ListIcon fontSize="inherit"></ListIcon>
                        Directory
                    </SideDrawerButton>
                    <SideDrawerButton
                        isSideDrawerOpen={isSideDrawerOpen}
                        onClick={() => {
                            props.history.push('/network')
                            setSelectedButtonId(4)
                        }}
                        isSelected={selectedButtonId === 4}
                    >
                        <BubbleChartIcon fontSize="inherit"></BubbleChartIcon>
                        Network
                    </SideDrawerButton>
                </div>
            </div>
        </>
    )
}

const SearchBar = props => {
    return (
        <div className="nav-bar-search">
            <SearchIcon fontSize="small"></SearchIcon>
            <input placeholder="Search..."></input>
        </div>
    )
}

const ColorNavBar = props => {
    const { hideSideDrawer } = props
    return (
        <>
            <div className="color-nav-bar">
                <NavBarButton
                    onClick={() => {
                        props.history.push('/')
                    }}
                >
                    <img
                        className="nav-bar-logo"
                        src={TechAseanLogoWhite}
                    ></img>
                </NavBarButton>

                <div>
                    {/* <SearchBar></SearchBar> */}
                    <NavBarButton
                        onClick={() => {
                            props.history.push('/')
                        }}
                    >
                        HOME
                    </NavBarButton>
                    <NavBarButton
                        onClick={() => {
                            props.history.push('/about_us')
                        }}
                    >
                        ABOUT
                    </NavBarButton>
                    {/* <NavBarButton
                        onClick={() => {
                            props.history.push('/login')
                        }}
                    >
                        LOGIN
                    </NavBarButton> */}
                </div>
            </div>
            {!hideSideDrawer && (
                <SideDrawer
                    handleSideDrawer={props.handleSideDrawer}
                    history={props.history}
                ></SideDrawer>
            )}
        </>
    )
}

ColorNavBar.propTypes = {}

export default withRouter(ColorNavBar)
