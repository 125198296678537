import './index.scss'
import React, { Component } from 'react'
import EntityCard from 'components/Card/EntityCard'
import constants from 'constants.js'
import Grid from '@material-ui/core/Grid'
import RoundedLightButton from 'components/Button/RoundedLightButton'

class HomeTab extends Component {
    state = {
        search: '',
    }

    render() {
        return (
            <div className="home-tab">
                <div className="home-section">
                    <Grid container spacing={1}>
                        <Grid container className="home-header">
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                className="header-section"
                            >
                                <h3>
                                    <span className="header-section-sub-text">
                                        Explore ASEAN's
                                    </span>
                                    <br></br>
                                    <span className="header-section-text">
                                        Startup <br></br>Ecosystem
                                    </span>
                                </h3>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                className="text-align-right"
                            >
                                <div className="count-section">
                                    <div className="count-section-count">
                                        25,000
                                    </div>
                                    <p className="count-section-text">
                                        organizations and counting...
                                    </p>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} className="home-buttons">
                            <Grid item xs={12} sm={4}>
                                <RoundedLightButton
                                    width={'100%'}
                                    onClick={() =>
                                        this.props.history.push(`/entities`, {
                                            roleId:
                                                constants.roles.STARTUP_ROLE,
                                        })
                                    }
                                >
                                    Startups
                                </RoundedLightButton>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <RoundedLightButton
                                    width={'100%'}
                                    onClick={() =>
                                        this.props.history.push(`/entities`, {
                                            roleId:
                                                constants.roles.INVESTOR_ROLE,
                                        })
                                    }
                                >
                                    Investors
                                </RoundedLightButton>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <RoundedLightButton
                                    width={'100%'}
                                    onClick={() =>
                                        this.props.history.push(`/entities`, {
                                            roleId:
                                                constants.roles
                                                    .ACCELERATOR_ROLE,
                                        })
                                    }
                                >
                                    Accelerators
                                </RoundedLightButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }
}

HomeTab.propTypes = {}

export default HomeTab
