import './index.scss'
import React, { memo } from 'react'
import SearchIcon from '@material-ui/icons/Search'

const SearchBar = props => {
    const { text, onChange, stateToChange, placeholder } = props

    return (
        <div className="search-bar">
            <SearchIcon fontSize="small"></SearchIcon>
            <input
                value={text}
                onChange={e => onChange(stateToChange, e)}
                placeholder={placeholder}
            ></input>
        </div>
    )
}

export default memo(SearchBar)
