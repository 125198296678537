import './index.scss'
import React, { memo } from 'react'

const PrivacyPolicyTab = props => {
    return (
        <div className="privacy-policy-tab container-padding">
            <h3>Privacy Policy</h3>
            <p>Effective date: May 30, 2018</p>
            <p>
                Tech ASEAN ("us", "we", or "our") operates the
                https://techasean.io website (the "Service").
            </p>
            <p>
                This page informs you of our policies regarding the collection,
                use, and disclosure of personal data when you use our Service
                and the choices you have associated with that data. This Privacy
                Policy for TechASEAN is powered by TermsFeed.
            </p>
            <p>
                We use your data to provide and improve the Service. By using
                the Service, you agree to the collection and use of information
                in accordance with this policy. Unless otherwise defined in this
                Privacy Policy, terms used in this Privacy Policy have the same
                meanings as in our Terms and Conditions, accessible from
                https://techasean.io
            </p>

            <h5>Information Collection And Use</h5>
            <p>
                We collect several different types of information for various
                purposes to provide and improve our Service to you.
            </p>
            <p>Types of Data Collected</p>
            <p>Personal Data</p>
            <p>
                While using our Service, we may ask you to provide us with
                certain personally identifiable information that can be used to
                contact or identify you ("Personal Data"). Personally
                identifiable information may include, but is not limited to:
            </p>
            <ul>
                <li>Email address</li>
                <li>First name and last name</li>
                <li>Phone number</li>
                <li>Address, State, Province, ZIP/Postal code, City</li>
                <li>Cookies and Usage Data</li>
            </ul>
            <h6>Usage Data</h6>
            <p>
                We may also collect information how the Service is accessed and
                used ("Usage Data"). This Usage Data may include information
                such as your computer's Internet Protocol address (e.g. IP
                address), browser type, browser version, the pages of our
                Service that you visit, the time and date of your visit, the
                time spent on those pages, unique device identifiers and other
                diagnostic data.
            </p>
            <h6>Tracking & Cookies Data</h6>
            <p>
                We use cookies and similar tracking technologies to track the
                activity on our Service and hold certain information.
            </p>
            <p>
                Cookies are files with small amount of data which may include an
                anonymous unique identifier. Cookies are sent to your browser
                from a website and stored on your device. Tracking technologies
                also used are beacons, tags, and scripts to collect and track
                information and to improve and analyze our Service.
            </p>
            <p>
                You can instruct your browser to refuse all cookies or to
                indicate when a cookie is being sent. However, if you do not
                accept cookies, you may not be able to use some portions of our
                Service.
            </p>
            <p>Examples of Cookies we use:</p>
            <ul>
                <li>
                    Session Cookies. We use Session Cookies to operate our
                    Service.
                </li>
                <li>
                    Preference Cookies. We use Preference Cookies to remember
                    your preferences and various settings.
                </li>
                <li>
                    Security Cookies. We use Security Cookies for security
                    purposes.
                </li>
            </ul>
        </div>
    )
}

PrivacyPolicyTab.propTypes = {}

export default memo(PrivacyPolicyTab)
