import './index.scss'
import React, { memo } from 'react'

const ColorRoundedLightButton = props => {
    const { width } = props
    return (
        <button
            className="custom-btn dark-btn color-rounded-light-btn"
            style={{ width }}
            onClick={props.onClick}
        >
            {props.children}
        </button>
    )
}

ColorRoundedLightButton.propTypes = {}

export default memo(ColorRoundedLightButton)
