import axios from 'axios'

const TECH_ASEAN_API_URL =
    process.env.REACT_APP_TECH_ASEAN_API_URL || 'http://localhost:8000/api'

const techAseanRequest = {
    get: (url, params) => {
        return axios({
            method: 'get',
            params,
            baseURL: `${TECH_ASEAN_API_URL}${url}`,
        })
    },
}

export default techAseanRequest
