import './index.scss'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles(theme => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 700,
    },
}))

export default function PaginatedTable(props) {
    const { headers, rows, totalNumRows, handlePageChange, onRowClick } = props
    const page = props.page
    const classes = useStyles()

    return (
        <div className="paginated-table">
            <Paper className={classes.paper}>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'} // can put 'medium'
                        aria-label="enhanced table"
                    >
                        <TableHead className="paginated-table-header">
                            <TableRow>
                                {headers.map(header => (
                                    <TableCell key={header} align="left">
                                        {header}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map(row => (
                                <TableRow
                                    key={row.id}
                                    onClick={() => {
                                        onRowClick(row.id)
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.country_origin}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={totalNumRows}
                    rowsPerPage={10}
                    page={page}
                    onChangePage={handlePageChange}
                />
            </Paper>
        </div>
    )
}

PaginatedTable.defaultProps = {
    page: 0,
    totalNumRows: 0,
}
