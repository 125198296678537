import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import PropTypes from "prop-types";

import { login, logout } from "../../redux/auth"

const PrivateRoute = ({ auth, login, logout, ...otherProps }) => {
  useEffect(() => {
    axios.get("/user").then(res => {
      login(res.data)
    }).catch(err => {
      logout()
    })
  }, [otherProps.path])
  return auth.user.isAuthenticated ? (
    <Route {...otherProps} />
  ) : (
      <Redirect to="/login" />
    );
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = { login, logout }

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
