import './index.scss'
import React, { Component } from 'react'
import techAseanRequest from 'utilities/techAseanRequest'
import DarkButton from 'components/Button/DarkButton'
import graphUtils from 'utilities/graphUtils.js'
import fullLinksArray from 'sample_data/fullLinksArray.json'
import shortestPaths from 'sample_data/shortestPaths.json'
import comprehensiveNodes from 'sample_data/comprehensiveNodes.json'
import NetworkGraph from 'components/NetworkGraph/NetworkGraph'
import Grid from '@material-ui/core/Grid'
import constants from 'constants.js'
import {
    Language as LanguageIcon,
    Facebook as FacebookIcon,
    LinkedIn as LinkedInIcon,
    Twitter as TwitterIcon,
} from '@material-ui/icons'

const data = {
    id: 22880,
    parent_role_id: 31,
    author_id: 5,
    image_id: 10606,
    name: 'Carousell',
    website: 'https://sg.carousell.com/',
    contact_email: 'team@thecarousell.com',
    contact_no: '',
    private_contact_no: 0,
    description:
        'Carousell is a Singapore-based consumer-to-consumer marketplace application for iOS and Android.',
    version: 29,
    active: 1,
    created_at: '2019-07-16T14:38:20.000Z',
    updated_at: '2020-02-11T06:30:12.000Z',
    deleted_at: null,
    alias: '',
    keywords: '',
    tags: '',
    internal_id: 'SG-S.8000',
    country_origin: 'Singapore',
    official_name: 'Carousell Pte. Ltd.',
    facebook_url: 'ss',
    twitter_url: 'ss',
    linked_in_url: '',
}

const Tab = props => {
    const { onClick, isSelected } = props
    return (
        <div
            onClick={onClick}
            className={
                'entity-detail-tab-bar-item ' +
                (isSelected ? 'selected-tab' : '')
            }
        >
            {props.children}
        </div>
    )
}

const TabBar = props => {
    const { handleChange, selectedRoleId } = props
    return (
        <div className="entity-detail-tab-bar">
            <Tab
                isSelected={selectedRoleId === 1}
                onClick={() => {
                    handleChange(1)
                }}
            >
                Overview
            </Tab>
            <Tab
                isSelected={selectedRoleId === 2}
                onClick={() => {
                    handleChange(2)
                }}
            >
                Relationships
            </Tab>
        </div>
    )
}

class EntityDetail extends Component {
    state = {
        entity: {},
        degreeWidth: 3,
        showUserNode: true,
        tabId: 1,
        selectedNode: null,
        noNetworkGraph: true,
        networkGraphTickCount: 40,
        networkGraphNodeArray: [],
        networkGraphLinksArray: [],
        networkGraphSelectedNodeId: -1,
        networkGraphIsAnimate: false,
        networkGraphIsChanged: false,
    }

    componentWillMount() {
        const { match } = this.props
        const entityId = parseInt(match.params.entityId)

        techAseanRequest
            .get(`/entities/${entityId}`)
            .then(({ data }) => {
                console.log(data)
                this.setState({
                    entity: data[0],
                })

                if (comprehensiveNodes.data[entityId]) {
                    this.renderLocalNetworkGraph(
                        comprehensiveNodes.data[entityId]
                    )
                }
            })
            .catch(err => {
                console.log(err)
            })

        // this.setState({
        //     entity: data,
        // })

        const {
            roles: { STARTUP_ROLE, FOUNDER_ROLE, ACCELERATOR_ROLE },
        } = constants

        const connectedEntities = {
            [STARTUP_ROLE.id]: [],
            [FOUNDER_ROLE.id]: [],
            [ACCELERATOR_ROLE.id]: [],
        }

        if (comprehensiveNodes.data[entityId]) {
            comprehensiveNodes.data[entityId].connections.forEach(
                connection => {
                    if (connection.neighbourRoleId === STARTUP_ROLE.id) {
                        connectedEntities[STARTUP_ROLE.id].push(connection)
                    }
                    if (connection.neighbourRoleId === FOUNDER_ROLE.id) {
                        connectedEntities[FOUNDER_ROLE.id].push(connection)
                    }
                    if (connection.neighbourRoleId === ACCELERATOR_ROLE.id) {
                        connectedEntities[ACCELERATOR_ROLE.id].push(connection)
                    }
                }
            )
        }

        this.setState({
            connectedEntities,
        })
    }

    renderFounders = () => {
        const founders = this.state.connectedEntities[
            constants.roles.FOUNDER_ROLE.id
        ]

        if (founders.length === 0) {
            return null
        }

        return (
            <div className="entity-detail-side-section-item">
                <h6 className="header">FOUNDERS</h6>
                <div className="content">
                    {founders.map(founder => {
                        return (
                            <div className="founder-item">
                                <p key={founder.neighborId}>
                                    {founder.neighbourName}
                                </p>
                                {founder.neighbourWebsite && (
                                    <LinkedInIcon
                                        style={{ cursor: 'pointer' }}
                                        color="primary"
                                        onClick={() =>
                                            window.open(
                                                founder.neighbourLinkedIn,
                                                '_blank'
                                            )
                                        }
                                    />
                                )}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    renderLocalNetworkGraph = node => {
        const { degreeWidth, showUserNode } = this.state
        const entityPairsOnShortestPath = graphUtils.buildShortestPath(
            node.id,
            shortestPaths
        )
        const localNodes = graphUtils.constructLocalNodesObj(
            node,
            degreeWidth,
            comprehensiveNodes,
            showUserNode,
            shortestPaths
        )
        let nodeArray = graphUtils.constructNodeArrayFromNodeObj(localNodes)
        let linksArray = graphUtils.constructLocalLinksArray(
            fullLinksArray.data,
            localNodes,
            entityPairsOnShortestPath,
            showUserNode
        )
        this.setState({
            selectedNode: node,
        })

        if (nodeArray.length > 0) {
            this.setState({ noNetworkGraph: false })
            this.renderNetworkGraph(nodeArray, linksArray, node.id, true)
        }
    }

    handleTabChange = tabId => {
        this.setState({ tabId })
    }

    renderNetworkGraph = (nodeArray, linksArray, selectedNodeId, isAnimate) => {
        const { networkGraphIsChanged } = this.state
        console.log(this.state)
        this.setState({
            networkGraphNodeArray: nodeArray,
            networkGraphLinksArray: linksArray,
            networkGraphSelectedNodeId: selectedNodeId,
            networkGraphIsAnimate: isAnimate,
            networkGraphIsChanged: !networkGraphIsChanged,
        })
    }

    render() {
        const {
            entity,
            tabId,
            networkGraphNodeArray,
            networkGraphLinksArray,
            networkGraphSelectedNodeId,
            networkGraphIsAnimate,
            networkGraphIsChanged,
            networkGraphTickCount,
        } = this.state

        return (
            <div className="container-padding">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={9}>
                        <div className="entity-detail-main-section">
                            <div className="header">
                                <h3>{entity.name}</h3>
                                <div className="links">
                                    {entity.facebook_url && (
                                        <FacebookIcon
                                            style={{
                                                cursor: 'pointer',
                                                marginRight: 10,
                                            }}
                                            onClick={() =>
                                                window.open(
                                                    entity.facebook_url,
                                                    '_blank'
                                                )
                                            }
                                        />
                                    )}
                                    {entity.twitter_url && (
                                        <TwitterIcon
                                            style={{
                                                cursor: 'pointer',
                                                marginRight: 20,
                                            }}
                                            onClick={() =>
                                                window.open(
                                                    entity.twitter_url,
                                                    '_blank'
                                                )
                                            }
                                        />
                                    )}
                                    {entity.website && (
                                        <DarkButton
                                            onClick={() =>
                                                window.open(
                                                    entity.website,
                                                    '_blank'
                                                )
                                            }
                                        >
                                            VIEW WEBSITE
                                        </DarkButton>
                                    )}
                                </div>
                            </div>
                            <TabBar
                                selectedRoleId={tabId}
                                handleChange={this.handleTabChange}
                            ></TabBar>
                            <div className="section">
                                {tabId === 1 && (
                                    <>
                                        <p>{entity.description}</p>
                                        {entity.contact_email && (
                                            <p>
                                                Contact Email:{' '}
                                                <a
                                                    href={`mailto:{}`}
                                                    className="contact"
                                                >
                                                    {entity.contact_email}
                                                </a>
                                            </p>
                                        )}
                                    </>
                                )}
                                {!this.state.noNetworkGraph && (
                                    <NetworkGraph
                                        nodeArray={networkGraphNodeArray}
                                        linksArray={networkGraphLinksArray}
                                        selectedNodeId={
                                            networkGraphSelectedNodeId
                                        }
                                        isAnimate={networkGraphIsAnimate}
                                        isChanged={networkGraphIsChanged}
                                        tickCount={networkGraphTickCount}
                                        renderLocalNetworkGraph={
                                            this.renderLocalNetworkGraph
                                        }
                                    ></NetworkGraph>
                                )}
                            </div>
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={3}
                        className="entity-detail-side-section"
                    >
                        <div className="entity-detail-side-section-item">
                            <h6 className="header">ABOUT</h6>
                            <div className="content">
                                <div className="item">
                                    <h6>Country</h6>
                                    <p>{entity.country_origin || '-'}</p>
                                </div>
                                <div className="item">
                                    <h6>Legal Name</h6>
                                    <p>{entity.official_name || '-'}</p>
                                </div>
                                <div className="item">
                                    <h6>Employees</h6>
                                    <p>{'-'}</p>
                                </div>
                            </div>
                        </div>

                        <div className="content">{this.renderFounders()}</div>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

EntityDetail.propTypes = {}

export default EntityDetail
