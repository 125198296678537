import './index.scss'
import React, { memo } from 'react'

const RoundedLightButton = props => {
    const { width } = props
    return (
        <button
            className="custom-btn light-btn rounded-light-btn"
            style={{ width }}
            onClick={props.onClick}
        >
            {props.children}
        </button>
    )
}

RoundedLightButton.propTypes = {}

export default memo(RoundedLightButton)
