import './index.scss'
import React, { memo } from 'react'
import comprehensiveNodes from 'sample_data/comprehensiveNodes.json'
import shortestPaths from 'sample_data/shortestPaths.json'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'

const ShortestPath = props => {
    let { targetNodeId } = props
    let renderLocalNetworkGraph = props.renderLocalNetworkGraph || (() => {})

    if (!targetNodeId) return null

    let shortestPathArray = shortestPaths.data[targetNodeId] || []

    const renderShortestPath = () => {
        return shortestPathArray.map((entity, index) => {
            const entityName = comprehensiveNodes.data[entity].name
            const id = comprehensiveNodes.data[entity].id
            return (
                <div>
                    {index !== 0 && <ArrowRightAltIcon></ArrowRightAltIcon>}
                    <div
                        className="shortest-path-entity"
                        onClick={() => {
                            renderLocalNetworkGraph(id)
                        }}
                    >
                        {entityName}
                    </div>
                </div>
            )
        })
    }

    return (
        <div>
            {shortestPathArray.length <= 1 ? (
                'No shortest path found'
            ) : (
                <div className="shortest-path">
                    Shortest Path: {renderShortestPath()}
                </div>
            )}
        </div>
    )
}

ShortestPath.propTypes = {}

export default memo(ShortestPath)
